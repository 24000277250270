import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const _ = require("lodash");

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// import PropTypes from "prop-types";

// import Headline from "../Article/Headline";
// import Bodytext from "../Article/Bodytext";

const MCCMNCList = props => {
  const query = useStaticQuery(graphql`
    query AllMCCMNC {
      allTs25ExtractCsv(
        sort: { fields: Country_Initials }
        filter: { MCC: { ne: "" }, MNC: { ne: "" } }
      ) {
        nodes {
          Country_Initials
          Network
          MCC
          MNC
        }
      }
      allE212Csv(
        sort: { fields: Country_code__ISO_CC3_ }
        filter: { E212_MCC: { ne: "" }, E212_MNC: { ne: "" } }
      ) {
        nodes {
          Country_code__ISO_CC3_
          E212_MCC
          E212_MNC
          Network_name
        }
      }
    }
  `);

  let mccmncTS25 = Array.from(new Set(query.allTs25ExtractCsv.nodes.map(JSON.stringify))).map(
    JSON.parse
  );

  mccmncTS25 = mccmncTS25.map(e => {
    e.Country = countries.getName(e.Country_Initials, "en") || "N/A";
    e.ISO = e.Country_Initials;
    delete e.Country_Initials;
    e.Operator = e.Network;
    delete e.Network;
    e.MCCMNC = e.MCC + e.MNC;
    return e;
  });
  // console.log(mccmncTS25.length)

  let mccmncE212 = Array.from(new Set(query.allE212Csv.nodes.map(JSON.stringify))).map(JSON.parse);

  mccmncE212 = mccmncE212.map(e => {
    e.Country = countries.getName(e.Country_code__ISO_CC3_, "en") || "N/A";
    e.ISO = e.Country_code__ISO_CC3_;
    delete e.Country_code__ISO_CC3_;
    e.MCC = e.E212_MCC;
    delete e.E212_MCC;
    e.MNC = e.E212_MNC;
    delete e.E212_MNC;
    e.Operator = e.Network_name;
    delete e.Network_name;
    e.MCCMNC = e.MCC + e.MNC;
    return e;
  });
  // console.log(mccmncE212.length)

  let allMCCMNC = [...mccmncTS25, ...mccmncE212];
  // console.log(allMCCMNC.length)

  allMCCMNC = _.uniqBy(allMCCMNC, "MCCMNC");
  allMCCMNC = _.orderBy(allMCCMNC, ["Country", "MCC", "MNC"], ["asc", "asc", "asc"]);
  // console.log(allMCCMNC)

  // const tableOptions = {
  //   headers: [{
  //     name: 'MCC',
  //     title: 'MCC'
  //   },{
  //     name: 'MNC',
  //     title: 'MNC'
  //   },{
  //     name: 'Operator',
  //     title: 'Operator Name'
  //   },{
  //     name: 'Country',
  //     title: 'Country'
  //   }]
  // }
  // const table = tableify(allMCCMNC, tableOptions)

  return (
    <React.Fragment>
      {/*<div dangerouslySetInnerHTML={{ __html: table }} />*/}
      <table>
        <thead>
          <tr>
            <th>MCC</th>
            <th>MNC</th>
            <th>Operator Name</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {allMCCMNC.map(row => {
            return (
              <tr key={row.MCCMNC}>
                <td>
                  <Link to={`/mccmnc/${row.MCCMNC}`}>{row.MCC}</Link>
                </td>
                <td>
                  <Link to={`/mccmnc/${row.MCCMNC}`}>{row.MNC}</Link>
                </td>
                <td>{row.Operator}</td>
                <td>{row.Country}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default MCCMNCList;
